import {
  CARD_DETAIL_COLOR,
  COMMON_MESSAGES,
  LICENSES_TYPES_NAME_TABLE,
  COMMON_ERRORS,
  MANUAL_LICENSE_TYPES_NAMES,
  MANUAL_LICENSE_TYPE_OTHERS,
  SHARED_PERMISOS_TYPES,
  TREE_STATUS_KEYS,
  allItemsFilter,
  ganttLicenses,
  sharedPermissions,
  tdSchedulerUserHeaders,
  timeToRequestMedicalExtension,
  LICENSES_STATUS,
  LIMIT_NUM_TICKET,
  TICKET_TYPE,
  WARNINGS_EMERGENCY_ACTIVATION,
} from 'src/utils/Constants';
import { licenseURLtypes } from './Constants';
import dayjs from 'dayjs';
import {
  getKeysExcept,
  toUpperCaseMonth,
} from '@components/pages/license/TableLicences/helperTable';
import {
  formatDate,
  formatDateToReadableMonth,
  formatRequestDateEndOfDay,
} from './date';
import theme from 'src/theme';
import { DATETIME_FORMAT, DATE_FORMAT } from 'react-big-scheduler-stch';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';
import { StyledTableCell } from '@components/pages/license/TableLicences/TableLicences.styled';
import { CustomTooltip } from '@components/CustomTooltip';

export const getUserFilters = (params) => {
  let filters = params.userName
    ? 'username_filter=' + encodeURIComponent(params.userName.trim())
    : '';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  filters += params.id_user ? '&id_user=' + params.id_user : '';
  filters += params.name
    ? '&name=' + encodeURIComponent(params.name.trim())
    : '';
  filters += params?.managments
    ? '&gerencia=' + encodeURIComponent(params?.managments.trim())
    : '';
  filters += params?.query
    ? '&q=' + encodeURIComponent(params?.query.trim())
    : '';
  filters += params?.indice ? '&indice=' + params?.indice : '';
  filters += params?.lista ? '&lista=' + params?.lista : '';

  return filters;
};
export const getSearchFilters = (params) => {
  let filters = params?.q ? '&q=' + encodeURIComponent(params?.q.trim()) : '';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';

  return filters;
};

export const getURLKeys = (keyParams) => {
  if (keyParams && keyParams !== '') {
    return keyParams.split(',');
  }
  return [];
};

export const getInfoTableTopes = (anteriores, mes, trabajadas) => {
  const hoy = new Date();
  const dia = hoy.getDate();
  let ciclo = hoy.getMonth();
  let horas = String(Math.trunc(anteriores / 60)).padStart(2, '0');
  let min = String(anteriores % 60).padStart(2, '0');

  if (dia > 11) {
    ciclo += 2;
  }
  let info = anteriores.find((element) => element.mes === mes) || '0';
  if (mes === ciclo) {
    horas = String(Math.trunc(trabajadas / 60)).padStart(2, '0');
    min = String(trabajadas % 60).padStart(2, '0');
    info = 1;
  }
  if (info === 0) {
    return <StyledTableCell>{'00:00'}</StyledTableCell>;
  }
  return <StyledTableCell>{horas + ':' + min}</StyledTableCell>;
};

export const getHoursTableTopes = (minutos, tope) => {
  const horas = String(Math.trunc(minutos / 60)).padStart(2, '0');
  const min = String(minutos % 60).padStart(2, '0');
  const topemin = tope * 60;
  const porcentaje = Math.trunc((minutos * 100) / topemin);
  const colour =
    porcentaje >= 100
      ? theme.palette.common.red
      : porcentaje >= 80
      ? theme.palette.common.orange
      : theme.palette.common.black;
  return (
    <StyledTableCell color={colour}>
      {`${horas}:${min}/${tope}(${porcentaje}%)`}
    </StyledTableCell>
  );
};

export const getMonthsTableTopes = (meses, anio) => {
  return (
    <StyledTableCell width={'4%'} fontWeight={600}>
      <CustomTooltip
        title={`11/${
          meses.mes < 10 ? '0' + meses.mes : meses.mes
        }/${anio} al 10/${
          meses.mes < 9
            ? '0' + (meses.mes + 1)
            : meses.mes < 12
            ? meses.mes + 1
            : '01'
        }/${meses.mes < 12 ? anio : anio + 1}`}
      >
        {`${meses.nombre}`}
      </CustomTooltip>
    </StyledTableCell>
  );
};

export const getTypeFilter = (arrayType) => {
  return arrayType?.length > 0 ? arrayType : null;
};

function removeEmptyProperties(objeto) {
  const nuevoObjeto = {};
  for (const clave in objeto) {
    if (objeto[clave] !== '') {
      nuevoObjeto[clave] = objeto[clave];
    }
  }
  return nuevoObjeto;
}

export const getUrlFilters = (params) => {
  const searchParams = new URLSearchParams();
  const entries = Object.entries(removeEmptyProperties(params));
  entries.map(([key, value]) => searchParams?.append(key, value));
  const queryString = decodeURIComponent(searchParams?.toString());

  return '?' + queryString;
};
export const buildBodyRequestForm = (files) => {
  var fd = new FormData();
  for (let index = 0; index < files.length; index++) {
    const f = files[index];
    fd.append('file_list', f);
  }
  return fd;
};

export const isMedicalLicense = (license) => {
  return license?.type === licenseURLtypes.enfermedad;
};

export const isProcedureLicense = (license) => {
  return license?.type === licenseURLtypes.tramites;
};

export const isVacationLicense = (license) => {
  return license?.type === licenseURLtypes.vacaciones;
};

export const buildFullUsername = (data) =>
  `${data?.first_name} ${data?.last_name}`;

export const getDaysForType = (licensesDetails) => {
  const days = licensesDetails?.days;
  const type = licensesDetails?.type;

  return days && type
    ? {
        ceres: days[type]?.ceres,
        in_process: days[type]?.in_process,
        available: days[type]?.available,
        requested: days[type]?.requested,
        remaining: days[type]?.remaining,
        returned: days[type]?.returned,
        toBeDiscount: days[type]?.a_descontar_sueldo,
      }
    : {};
};

export const onKeyDownEscape = (event, setOpen) => {
  if (event.key === 'Escape') {
    setOpen(false);
  }
};

export const createTextLinks = (text) => {
  return (text.toString() || '').replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    function (match, space, url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return (
        space +
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noreferrer">' +
        url +
        '</a>'
      );
    }
  );
};

export const getIdFromUrl = (friendlyUrl) => {
  return friendlyUrl && friendlyUrl.split('-')[0];
};

export const remplaceUrlFromProd = (url) => {
  if (isProdHost() && isUrlProd(url))
    return url.replace('https://portal.arsat.com.ar', '');
  return url;
};

export const buildTargetLink = (url) => {
  return isRelativeUrl(url) || (isProdHost() && isUrlProd(url)) ? '_self' : url;
};

export const isRelativeUrl = (url) => {
  return !(url.indexOf('://') > 0 || url.indexOf('//') === 0);
};

export const isProdHost = () => {
  return window.location.host === process.env.NEXT_PUBLIC_PORTAL_HOST;
};

export const isUrlProd = (url) => {
  return url?.includes(process.env.NEXT_PUBLIC_PORTAL_HOST) || false;
};

export const getMultiErrors = (array) => {
  return array?.length > 0 ? (
    <>
      {array?.map((item) => (
        <div key={item}>{COMMON_ERRORS[item] || item} </div>
      ))}
    </>
  ) : (
    COMMON_MESSAGES.error
  );
};
export const getMultiErrorsText = (array) => {
  const multiErrorText = [];
  array?.forEach((item) => multiErrorText.push(COMMON_ERRORS[item] || item));
  return multiErrorText?.length > 0 ? COMMON_ERRORS[array] : multiErrorText;
};

export const isValidDate = (value) => {
  const selectedDate = dayjs(value);
  return !selectedDate.isValid();
};

export const dateCurrentYear = (value) => {
  const currentYear = dayjs().year();
  const selectedDate = dayjs(value);
  return selectedDate.year() !== currentYear;
};

export const isDatePast = (value) => {
  const minDate = dayjs().add(-1, 'd');
  const selectedDate = dayjs(value);
  return !minDate.isBefore(selectedDate);
};

export const formatDayCard = (date) => {
  const readableDate = toUpperCaseMonth(formatDateToReadableMonth(date));

  if (readableDate) {
    const [day, month, year] = readableDate.split(' ');
    const abbreviatedMonth = month.length > 3 ? month.slice(0, 3) : month;
    const formattedDate = `${day} ${abbreviatedMonth} ${year}`;
    return formattedDate;
  } else {
    readableDate;
  }
};

export const scrollToRouteHash = () => {
  const hash = getRouteHash();
  return hash && scrollToElementById(hash);
};

export const getRouteHash = () => {
  return window.location.hash.substring(1);
};

export const scrollToElementById = (idElement) => {
  const elementToScroll = document.getElementById(idElement);
  if (!elementToScroll) return false;
  elementToScroll.scrollIntoView({ behavior: 'smooth' });
};

export const isTouchDevice = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

export const isOneYearLater = (value) => {
  const currentDate = dayjs();
  const nextYear = currentDate.add(1, 'year');
  const selectedDate = dayjs(value);
  return selectedDate.isAfter(nextYear, 'day');
};

export const getInitials = (firstName, lastName) => {
  const firstNameInitial = firstName?.[0] ?? '';
  const lastNameInitial = lastName?.[0] ?? '';

  const inicialesConcatenadas = firstNameInitial + lastNameInitial;
  return inicialesConcatenadas;
};

export const startOfMonth = (date) => {
  return dayjs(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
};

export const endOfMonth = (date) => {
  return dayjs(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
};

export const addOneMonth = (date) => {
  return dayjs(date).add(1, 'month').format('YYYY-MM-DD HH:mm:ss');
};
export const addOneWeek = (date) => {
  return dayjs(date).add(7, 'days').format('YYYY-MM-DD HH:mm:ss');
};
export const addOneYear = (date) => {
  return dayjs(date).add(1, 'year').format('YYYY-MM-DD HH:mm:ss');
};

export const subtractOneMonth = (date) => {
  return dayjs(date).subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss');
};

export const subtractOneWeek = (date) => {
  return dayjs(date).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
};
export const subtractOneYear = (date) => {
  return dayjs(date).subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss');
};

export const formatSchedulerData = (arrayEvents) => {
  arrayEvents.forEach((event) => {
    const type = event?.title;
    event.bgColor =
      (event.manual && !isTypeOthers(type)) ||
      (isTypeOthers(type) && event?.ausente)
        ? ganttLicenses?.manual?.color
        : ganttLicenses?.[type]?.color?.[event?.status_process] ||
          CARD_DETAIL_COLOR?.interrupted;
    event.startResizable = false;
    event.endResizable = false;
    event.start = formatDate(event?.start, DATE_FORMAT.SCHEDULER);
    event.end = formatDate(event.end, DATE_FORMAT.SCHEDULER);
  });
  return arrayEvents;
};
export const formatResourcesSchedulerData = (arrayResources) => {
  arrayResources.forEach((resource, index) => {
    resource.name = `${resource?.last_name}, ${resource?.first_name}`;
    resource.index = index;
  });
  return arrayResources;
};
export const isHoliday = (feriados, date) => {
  const holidayDates = feriados?.map(
    (feriado) => new Date(feriado.date).toISOString().split('T')[0]
  );
  const formattedDate = new Date(date).toISOString().split('T')[0];
  return holidayDates?.includes(formattedDate);
};

export const isAllSelected = (array, options) => {
  return (
    array?.sort()?.toString() ===
    getKeysExcept(options, allItemsFilter.value)?.sort()?.toString()
  );
};

export function previousSunday(fecha) {
  return dayjs(fecha).day(0).startOf('day').format(DATE_FORMAT.SCHEDULER);
}

export function nextSaturday(fecha) {
  return dayjs(fecha).day(6).endOf('day').format(DATE_FORMAT.SCHEDULER);
}

export function firstDayOfTheYear(date) {
  const year = date?.year();
  const firstDay = dayjs(`${year}-01-01`);

  return firstDay.format(DATETIME_FORMAT);
}
export function lastDayOfTheYear(date) {
  const year = date.year();
  const lastDay = formatRequestDateEndOfDay(
    dayjs(`${year}-12-31`).format(DATETIME_FORMAT)
  );

  return lastDay;
}
export function canRequestMedicalExtension(license) {
  const isMedical = isMedicalLicense(license);
  const isJustified = license?.status_process === TREE_STATUS_KEYS.justified;
  let isTimeAvailable = false;
  if (license?.to_date) {
    const toDate = moment(license?.to_date).add(
      timeToRequestMedicalExtension,
      'h'
    );
    const endTime = moment();
    isTimeAvailable = !endTime.isAfter(toDate);
  }

  return isMedical && isJustified && isTimeAvailable;
}
export function canSendMedicalMessage(license) {
  return (
    isMedicalLicense(license) &&
    (LICENSES_STATUS[license?.status_process] ===
      LICENSES_STATUS.extension_en_evaluacion ||
      LICENSES_STATUS[license?.status_process] ===
        LICENSES_STATUS.document_loaded)
  );
}

export const isManualLicense = ({ manual }) => {
  return manual || false;
};

export const buildNameOfTypeManualLicense = (license) => {
  const { type, title, notes } = license;
  const typeLicense = type || title;
  return isManualLicense(license)
    ? isTypeOthers(typeLicense)
      ? notes
      : MANUAL_LICENSE_TYPES_NAMES[typeLicense]
    : LICENSES_TYPES_NAME_TABLE[typeLicense];
};

export const isTypeOthers = (licenseType) => {
  return licenseType === MANUAL_LICENSE_TYPE_OTHERS;
};

export const getValidsSlotIds = (arrayIdUrl, arrayIdData) => {
  const idsData = arrayIdData.map((objeto) => objeto.id);

  const sameElements = idsData.filter((elemento) =>
    arrayIdUrl.includes(elemento)
  );

  return sameElements;
};

export const isViewBalance = (balance) => {
  return balance ? '' : 'true';
};

export const normalizedString = (string) => {
  if (!string) return string;

  return string
    .split('')
    .map((char) => {
      if (char === 'Ñ' || char === 'ñ') return char;
      return char.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    })
    .join('')
    .toLowerCase();
};
export const normalizedStringWithAccents = (string) => {
  if (!string) return string;

  return string.normalize('NFC').toLowerCase();
};

export const highlightMatches = (str1, str2) => {
  if (!str1 || !str2) return str2;
  const normalizedStr1 = normalizedString(str1);
  const normalizedStr2 = normalizedString(str2);

  const positions = [];
  const highlightedSlotName = [];

  normalizedStr2.split('').forEach((_, i) => {
    const substring = normalizedStr2.substr(i, str1.length);
    if (substring === normalizedStr1) {
      for (let j = i; j < i + str1.length; j++) {
        positions.push(j);
      }
    }
  });

  str2.split('').forEach((char, i) => {
    const isHighlighted = positions.includes(i);
    highlightedSlotName.push(
      <span
        key={i}
        style={{
          backgroundColor: isHighlighted
            ? theme.palette.common.yellowSearch
            : '',
        }}
      >
        {char}
      </span>
    );
  });

  return highlightedSlotName;
};

export const removeMultipleSlotIdFilter = (arrayIds, actualSlotIds) => {
  return actualSlotIds?.filter((item) => !arrayIds?.includes(item));
};

export const searchRelatedIds = (idBuscado, resources) => {
  let foundIds = [];

  resources?.forEach((objeto) => {
    if (objeto.parentId === idBuscado) {
      foundIds.push(objeto.id || objeto.slotId);
      foundIds = foundIds.concat(
        searchRelatedIds(objeto.id || objeto.slotId, resources)
      );
    }
  });

  return foundIds;
};

export const filterSelectedItems = (selectedArray, optionsArray) => {
  if (selectedArray?.length > 0)
    return optionsArray.filter((item) => !selectedArray.includes(item));
  else return optionsArray;
};

export const convertArrayToObject = (arrayOriginal) => {
  const object = {};

  arrayOriginal?.forEach((valor) => {
    object[valor] = valor;
  });

  return object;
};

export const getUserSharedById = ({ userData, idUserCompartido }) => {
  const userShared =
    userData?.compartido_conmigo?.length > 0
      ? userData?.compartido_conmigo.find(
          (user) => user.id_user == idUserCompartido
        )
      : null;
  return userShared;
};

export const canViewShared = ({ userShared }) => {
  if (!userShared) return false;
  return SHARED_PERMISOS_TYPES.includes(userShared?.permiso);
};

export const isEditorUserShared = ({ userShared }) => {
  if (!userShared) return false;
  return sharedPermissions.editor === userShared?.permiso;
};

export const formatPhoneNumberTypeFixed = (number) => {
  let result = '';
  let zone =
    number.substr(0, 1) === '0' ? number.substr(0, 4) : number.substr(0, 3);
  result = zone + '-' + number.substr(3, 3) + '-' + number.substr(6, 4);
  return result;
};

export const formatPhoneNumber = (numero) => {
  return numero && parsePhoneNumber(numero, 'AR')?.formatNational();
};

export const imageBase64 = (image, callback) => {
  const reader = new FileReader();
  reader.onload = () => {
    const base64Data = reader.result;
    callback(base64Data);
  };
  reader.readAsDataURL(image);
};

export const getFormData = (fileData, alt) => {
  let fd = new FormData();
  fd.append('file', fileData);
  fd.append('alt', alt);
  return fd;
};

export const setIdTdUserHeaders = (resources) => {
  const tdElements = document.querySelectorAll('.scheduler-content-table td');

  const hastd = tdElements?.length > 0;
  const hasResources = resources?.length > 0;

  if (hastd && hasResources)
    resources.forEach((element, index) => {
      tdElements[index]?.setAttribute(
        'id',
        index < 2 ? tdSchedulerUserHeaders : ''
      );
    });
};

export const isPhoneValidToShow = ({ tipo, numero, interno }) => {
  if (!numero) return false;
  if (tipo === 'fijo_benavidez' && !interno) return false;
  return true;
};

export const hasMultiplePhoneNumbers = (telefonos) => {
  let validNumberCounter = 0;
  telefonos?.forEach(({ numero }) => {
    if (numero.length > 0) {
      validNumberCounter++;
    }
  });
  if (validNumberCounter == 1) {
    return false;
  } else {
    return true;
  }
};

export const getLocationUser = (workPlace) => {
  const { lugar_trabajo, detalle, piso } = workPlace || {};
  let location = '';
  if (lugar_trabajo) location += lugar_trabajo;
  if (detalle) location += ` - ${detalle}`;
  if (piso) location += ` - ${piso}`;

  return location;
};

export const errorFilterDatePickerPaychecks = (query) => {
  const isBefore = dayjs(query?.from_date).isBefore(query?.to_date);
  if (!isBefore && query?.from_date && query?.to_date) {
    return '* La fecha "desde" debe ser menor a "hasta"';
  }
  return '';
};

export const isLocalhost = () => {
  return process.env.NODE_ENV === 'development';
};

export const diffHoursDates = (dateStart, dateEnd) => {
  const diferenciaMilisegundos = dateEnd.diff(dateStart);
  const diferenciaHoras = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60));
  const diferenciaMinutos = Math.floor(
    (diferenciaMilisegundos % (1000 * 60 * 60)) / (1000 * 60)
  );
  const horasFormateadas = String(diferenciaHoras || 0).padStart(2, '0');
  const minutosFormateados = String(diferenciaMinutos || 0).padStart(2, '0');

  return `${horasFormateadas}:${minutosFormateados}`;
};

export const readMinutesAsHours = (minutos) => {
  if (minutos === 0) return '0 horas';
  let horas = Math.floor(minutos / 60);
  let minutosSobrantes = Math.floor(minutos - horas * 60);
  if (horas && minutosSobrantes)
    return `${horas} horas ${minutosSobrantes} minutos`;
  if (horas) return `${horas} horas`;
  return `${minutosSobrantes} minutos`;
};

export const readMinutesAsTime = (minutos) => {
  if (minutos === 0) return '0:00';
  let horas = Math.floor(minutos / 60);
  let minutosSobrantes = Math.floor(minutos - horas * 60);
  if (horas && minutosSobrantes) return `${horas}:${minutosSobrantes}`;
  if (horas) return `${horas}:00`;
  return `0:${minutosSobrantes}`;
};

export const validateNumTicket = (value) => {
  if (value > LIMIT_NUM_TICKET?.MAX)
    return `El número máximo permitido es ${LIMIT_NUM_TICKET?.MAX}`;
  if (value < LIMIT_NUM_TICKET?.MIN)
    return `El número máximo permitido es ${LIMIT_NUM_TICKET?.MIN}`;
  return true;
};

export const minutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  return hours;
};

export const minutesToHoursWithDecimal = (minutes) => {
  const hours = Math.trunc(minutes / 60);
  let min = minutes % 60;
  if (min < 10) {
    min = '0' + min;
  }
  return hours + ':' + min;
};

export const getPercentage = (num, total) => {
  return Math.floor((num / total) * 100);
};

export const getSameDate = (from_date, to_date) => {
  const isSameDay = dayjs(from_date).isSame(to_date, 'day');
  let dates = formatDayCard(from_date);
  dates += to_date && !isSameDay ? ` • ${formatDayCard(to_date)}` : '';
  return dates;
};

export const readTicket = (ticket, withDescription) => {
  if (!ticket?.tipo || !ticket?.numero) return '';
  const ticketId = `${TICKET_TYPE[ticket?.tipo]}${ticket?.numero}`;
  if (withDescription) return `${ticketId} - ${ticket?.descripcion}`;
  return ticketId;
};

export const readEmergencyWarning = (warning) => {
  if (
    warning?.conflicto === WARNINGS_EMERGENCY_ACTIVATION.MAXIMO_ANUAL_SUPERADO
  ) {
    return 'Las horas informadas superan el máximo anual.';
  }
  if (
    warning?.conflicto === WARNINGS_EMERGENCY_ACTIVATION.MAXIMO_MENSUAL_SUPERADO
  ) {
    return 'Las horas informadas superan el máximo mensual.';
  }
  if (
    warning?.conflicto === WARNINGS_EMERGENCY_ACTIVATION.LICENCIA_COINCIDENTE
  ) {
    const readWarningType =
      warning?.type === 'vacation'
        ? 'por vacaciones'
        : warning?.type === 'procedure'
        ? 'por procedimiento'
        : warning?.type === 'exam'
        ? 'por examen'
        : warning?.type === 'medical'
        ? 'médica'
        : warning?.type === 'donacion_sangre'
        ? 'por donación de sangre'
        : warning?.type === 'donacion_de_sangre'
        ? 'por donación de sangre'
        : warning?.type === 'enfermedad_familiar'
        ? 'por enfermedad familiar'
        : warning?.type === 'art'
        ? 'por ART'
        : warning?.type === 'mudanza'
        ? 'por mudanza'
        : warning?.type === 'matrimonio'
        ? 'por matrimonio'
        : warning?.type === 'maternidad'
        ? 'por maternidad'
        : warning?.type === 'paternidad'
        ? 'por paternidad'
        : warning?.type === 'excedencia'
        ? 'por excedencia'
        : warning?.type === 'sin_goce_de_sueldo'
        ? 'sin goce de sueldo'
        : warning?.type === 'franco_compensatorio'
        ? 'por franco compensatorio'
        : warning?.type === 'compensacion_de_horas'
        ? 'por compensacion de horas'
        : warning?.type === 'bomberos'
        ? 'por trabajo como bombero voluntario'
        : warning?.type === 'deportiva'
        ? 'deportiva'
        : warning?.type === 'gremial'
        ? 'gremial'
        : warning?.type === 'otros'
        ? ''
        : '';
    return `Se registra una licencia ${readWarningType} en el período seleccionado`;
  }
};

export const getApprovalLimitDate = (inputDate) => {
  // Get the day of the month from the input Date object
  const formatInputDate = dayjs(inputDate);
  const day = formatInputDate.date();
  // Create a new Date object to return

  let returnDate = dayjs(inputDate).startOf('day');

  // If the day is between 0 and 11, set the day to the 14th of the current month
  if (day >= 0 && day <= 11) {
    returnDate = dayjs().set('date', 14);
  } else {
    // Otherwise, set the date to the 14th of the next month
    returnDate = dayjs().add(1, 'month').set('date', 14);
  }

  return returnDate;
};
export const getApprovalLimitDateDayjs = () => {
  // Get the day of the month from the input Date object
  const today = dayjs();
  const day = today.date();

  // If the day is between 0 and 11, set the day to the 14th of the current month
  if (day >= 0 && day <= 11) {
    return dayjs()
      .subtract(1, 'month')
      .set('date', 11)
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0);
  } else {
    return dayjs()
      .set('date', 11)
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0);
  }
};
export const isEmptyFiltersTabs = (state) => {
  if (state?.estados?.length > 0) return false;
  if (state?.q) return false;
  if (state?.from_date) return false;
  if (state?.to_date) return false;
  return true;
};

export const textTooltipReportButton = (disabled) => {
  if (disabled)
    return 'El envío del reporte se habilitará a partir del día 14 del mes.';
  return '';
};
